<template>
<base-popup
    :popup-model="show"
    :title="formTitle"
    :valid="!valid"
    :width="60"
    @onCancelButtonClick="closePopupHandler"
    @onSaveButtonClick="saveTemplateItemHandler"
>
  <v-form
      :ref="formRef"
      v-model="valid"
      lazy-validation
  >
    <v-container class="ma-2 mt-3">
      <div v-if="!(currentItem.type === 500 || currentItem.type === 600 || currentItem.type === 800)">
        <span class="text--black" style="font-size: 18px">Note:</span> If you add some variable into your template, use like: @name. Usable variable <b>{{replaceVariable}}</b>
      </div>
      <v-row class="mt-2">
    <v-col cols="12">
      <v-text-field
      v-model="currentItem.name"
      label="Template Name"
      :rules="requiredFieldRule"
      dense
      outlined
      />
      <v-text-field
              v-model="currentItem.templateText"
              label="Template Text"
              dense
              outlined
      />
      <v-text-field
          v-model="currentItem.subject"
          label="Mail Subject"
          :rules="requiredFieldRule"
          dense
          outlined
      />
      <vue-editor
          v-model="currentItem.description"
          :editorToolbar="customToolbar"
          :customModules="customModulesForEditor"
          :editorOptions="editorSettings"
          useCustomImageHandler
          @image-added="handleImageAdded"
      />
      <br>
      <div v-if="!(currentItem.type === 700)">
      <h2>Mail Template Details:</h2>
      <br>
      <template v-for="(item, key) in templateDetails">
      <v-row no-gutters>
        <v-col cols="12" class="mb-3 mt-3">
          Mail Details {{key + 1}} <v-btn
              v-if="key !==0"
              color="error"
              icon
              @click="mailTemplateDetailsRowRemoveHandler(key)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-btn
              v-if="templateDetails.length === key + 1 && !(templateDetails.length >= 10)"
              color="primary"
              fab
              x-small
              @click="mailTemplateDetailsRowHandler()"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-text-field
              v-model="item.subject"
              label="Subject"
              dense
              outlined
          />
        </v-col>
        <v-col cols="12" md="12">
          <vue-editor
              :id="`editor${key}`"
              v-model="item.description"
              :editorToolbar="customToolbar"
              :customModules="customModulesForEditor"
              :editorOptions="editorSettings"
              useCustomImageHandler
              @image-added="handleImageAdded"
          />
        </v-col>
      </v-row>
      </template>
      </div>
    </v-col>
  </v-row>
    </v-container>
  </v-form>
</base-popup>
</template>

<script>
import BasePopup from "./BasePopup";
import Popup from "../mixins/Popup";
import { VueEditor, Quill } from "vue2-editor";
import ImageResize from 'quill-image-resize-vue';
import MailTemplate from "../../services/MailTemplate";
Quill.register("modules/imageResize", ImageResize);

export default {
  name: "MailTemplateEditorPopup",
  mixins:[Popup],
  components: {BasePopup, VueEditor},
  data() {
    return {
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ color: [] }, { background: [] }],
        ["blockquote", "code-block", "link", "image"]
      ],
      customModulesForEditor: [
        { alias: "imageResize", module: ImageResize }
      ],
      editorSettings: {
        modules: {
          imageResize: {}
        }
      }
    }
  },
  computed: {
    formTitle() {
      return this.isEditMode ? 'Edit Mail Template' : 'Add Mail Template'
    },
    replaceVariable() {
      if(this.currentItem.type === 700) return '(links, orderId)';

      return '(name, email, totalAmount, amount, discount, invoiceNo, refcode, createdAt, payLink, paymentMail, updateMail, removalMail, links, mailBody)';
    }
  },
  methods: {
    mailTemplateDetailsRowRemoveHandler(index) {
      this.templateDetails.splice(index, 1)
    },
    mailTemplateDetailsRowHandler() {
      if (this.templateDetails.length >= 10) return;
      this.templateDetails.push({description: ''});
    },
    saveTemplateItemHandler() {
      if (!this.isFormValid) {
        return;
      }

      this.$emit('saveItemHandler', {...this.currentItem, templateDetails: this.templateDetails});
      this.closePopupHandler();
    },
    handleImageAdded(file, Editor, cursorLocation, resetUploader) {
        MailTemplate
            .imageUpload({tempImage: file, tempName: this.currentItem.name})
            .then((result) => {
              const url = result.data.url;
              Editor.insertEmbed(cursorLocation, "image", url);
              resetUploader();
            })
            .catch((errors)=> {

            })

    }
  }
}
</script>

<style scoped>

</style>