<template>
  <div>
    <list-page
        :columns="columns"
        :headers="headers"
        :popupComponent="popupComponent"
        :show-add-button="false"
        title="Mail Template List"
        icon="mdi-grid"
        stateendpoint="mailTemplate.mailTemplates"
        :show-default-action-buttons="false"
        :actions="actions"
        :action-buttons="actionButton"
    />
  </div>
</template>

<script>
import ListPage from "../components/ListPage";
import MailTemplateEditorPopup from "../components/popups/MailTemplateEditorPopup";
export default {
  name: "MailTemplates",
  metaInfo: {
    title: 'webexpressmedia.com',
    titleTemplate: 'Admin Dashboard - Mail Templates | %s',
    meta: [
      { name: 'robots', content: 'noindex' },
      { name: 'googlebot', content: 'noindex' }
    ]
  },
  components: {ListPage, MailTemplateEditorPopup},
  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Template Text',
          value: 'templateText'
        },
        {
          text: '', value: 'actions', sortable: false
        }
      ],
      columns: [
        {value: 'name'},
        {value: 'action'},
      ],
      actions: {
        load: 'loadMailTemplates',
        update: 'updateMailTemplate'
      },
      actionButton: [
        {
          category: 'edit',
          icon: 'mdi-pencil',
          color: '',
          text: 'Edit',
          clickHandler: 'editItem'
        }
       ]
    }
  },
  computed: {
    popupComponent() {
      return MailTemplateEditorPopup;
    }
  }
}
</script>

<style scoped>

</style>